import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  min-width: 100vw;
`;

const Option = styled.div`
  width: 250px;
  height: 250px;
  border-radius: 25px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  cursor: pointer;
`;

const Add = () => {
  const router = useHistory();
  return (
    <Container className="full flex vertical">
      <h1 className="title bold">Add a New Photo or Text</h1>
      <h3 className="title" style={{ marginBottom: 50 }}>
        Google CMS supports content from Google Photos and Google Sheets
      </h3>
      <div className="full flex wrap apart">
        <Option
          style={{ backgroundColor: "#41EAD4" }}
          onClick={() => router.push("/add/photo")}
        >
          <h1>Photos</h1>
        </Option>
        <Option
          style={{ backgroundColor: "#FAFF00" }}
          onClick={() => router.push("/add/sheet")}
        >
          <h1>Sheets</h1>
        </Option>
      </div>
    </Container>
  );
};

export default Add;
