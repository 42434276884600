import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./screens/login";
import Verify from "./screens/login/Verify";
import { useSelector } from "react-redux";
import { ApplicationState } from "./redux/store";
import Home from "./screens/home";

const DefaultRoute = () => {
  const user = useSelector((state: ApplicationState) => state.session.user);
  const token = user && user.accessToken;
  return <Home />
  if (token) return <Home />;
  else return <Login />;
};

const Routes = () => (
  <Switch>
    <Route path="/login" exact component={Login} />
    <Route path="/verify" exact component={Verify} />
    <Route path="/" component={DefaultRoute} />
  </Switch>
);

export default Routes;
