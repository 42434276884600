import React from "react";
import "./loading.css";
import styled from "styled-components";

const LoadingWrapper = styled.div`
position: absolute;
top: 50%;
left: 50%;
min-width: 100%;
min-height: 100%;
max-width: 100vw;
width: auto;
height: auto;
z-index: -100;
background: rgba(255, 255, 255, 0.25);
transform: translateX(-50%) translateY(-50%);
`
const Loading = () => (
  <LoadingWrapper className="flex center">
    <div className="lds-circle">
      <div></div>
    </div>
  </LoadingWrapper>
);

export default Loading;