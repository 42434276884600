import React, { useState, FC, useEffect } from "react";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import { gql, useLazyQuery } from "@apollo/client";

export interface ISheet {
  documentId: string;
  name: string;
  pageName: string;
  columnIndex: string;
  rowIndex: string;
}
interface ISheetForm {
  onSearch: (val: string) => void;
  onChange: (values: any) => void;
}
const SheetForm: FC<ISheetForm> = ({ onSearch, onChange }) => {
  const [values, setValues] = useState<any>({
    documentId: "1iLOzJVKUdOHCycAaf-UWKUM8QWfemzWX_70aqwu_oEw",
    name: "",
    pageName: "text",
    columnIndex: "B",
    rowIndex: 2,
  });
  const setValue: any = ({ target: { value, name } }: any) => {
    const newValues = { ...values, [name]: value };
    setValues(newValues);
    onChange(newValues);
  };
  const [getSheet, { data }] = useLazyQuery(GET_SHEET);
  useEffect(() => {
    if (data) onSearch(data?.cell?.text);
  }, [data, onSearch]);
  return (
    <div className="flex column">
      <Input
        value={values.documentId}
        placeholder="ID"
        name="documentId"
        onChange={setValue}
        required
      />
      <Input
        value={values.name}
        placeholder="Name"
        name="name"
        onChange={setValue}
        required
      />
      <Input
        value={values.pageName}
        placeholder="Page Name"
        name="pageName"
        onChange={setValue}
        required
      />
      <Input
        value={values.columnIndex}
        placeholder="Column"
        name="columnIndex"
        onChange={setValue}
        required
      />
      <Input
        value={values.rowIndex}
        placeholder="Row"
        name="rowIndex"
        onChange={setValue}
        required
      />
      <Button
        onClick={() => {
          onSearch("...Loading...");
          getSheet({
            variables: {
              item: { ...values, rowIndex: parseInt(values.rowIndex) },
            },
          });
        }}
      >
        {" "}
        Search{" "}
      </Button>
    </div>
  );
};

export default SheetForm;

const GET_SHEET = gql`
  query cell($item: CellInput!) {
    cell(item: $item) {
      text
    }
  }
`;
