import React, { useState } from "react";
import styled from "styled-components";
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { User } from "../../../redux/interfaces";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";
import { BsFillTrashFill, BsPencil, BsLink } from "react-icons/bs";
const Container = styled.div`
  overflow: scroll;
  margin: 50px;
`;
interface ITable {
  library: any;
  refetch: () => {};
  noContentMessage: string;
}

const Table: React.FC<ITable> = (props) => {
  const history = useHistory();
  const [onDeletePhoto] = useMutation(DELETE_PHOTO);
  const [onDeleteText] = useMutation(DELETE_TEXT);
  const user = useSelector((s: ApplicationState) => s.session.user!);

  return (
    <Container className="full flex">
      <div className="full flex">
        {props.library &&
        Array.isArray(props.library) &&
        props.library.length ? (
          props.library.map((content: any) => (
            <Row
              content={content}
              key={content.id}
              onDelete={content.type === "text" ? onDeleteText : onDeletePhoto}
              refetch={props.refetch}
              history={history}
              user={user}
              type={content.type}
            />
          ))
        ) : (
          <h5>{props.noContentMessage}</h5>
        )}
      </div>
    </Container>
  );
};

const Item = styled.div`
  width: 100px;
  max-width: 100px;
  height: 100px;
  max-height: 100px;
  background: grey;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
`;

const Row = ({ content, onDelete, refetch, user, history, type }: any) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <Item
      className="flex"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{
        background: `${
          content.type === "media"
            ? `url('${content.url}') no-repeat`
            : "lightgray"
        }`,
        backgroundSize: "cover",
      }}
    >
      {content.type === "text" && (
        <div
          style={{
            position: "absolute",
            fontSize: "1em",
            textOverflow: "ellipsis",
            maxWidth: 80,
            maxHeight: 80,
            padding: 5,
            overflow: "hidden",
            pointerEvents: "none",
          }}
          onClick={(e) => console.log("hit")}
        >
          {content.value}
        </div>
      )}
      {isHover && (
        <div
          style={{ background: "rgb(255,255,255,0.5)" }}
          className="full flex column center"
        >
          <div
            onClick={() => copyToClipboard(buildCMSUrl(content, user))}
            className="full flex center"
            style={{ width: "100%", border: "1px solid white" }}
          >
            <BsLink className="full" color="yellow" size="1.5em" />
          </div>
          <div className="full flex center" style={{ width: "100%" }}>
            <div
              onClick={() => history.push(`/edit/${content.id}`)}
              className="full flex center"
              style={{ height: "100%", border: "1px solid white" }}
            >
              <BsPencil className="full" size="1.5em" />
            </div>
            <div
              className="full flex center"
              style={{ height: "100%", border: "1px solid white" }}
              onClick={() => {
                onDelete({
                  variables: { item: { id: content.id, isDelete: true } },
                })
                  .then(() => refetch())
                  .catch(console.error);
              }}
            >
              <BsFillTrashFill className="full" size="1.5em" color="red" />
            </div>
          </div>
        </div>
      )}
    </Item>
  );
};

export const buildCMSUrl = (content: any, user: User) =>
  `https://api.photos.ollieware.com/cdn/${
    content.type === "media" ? "media" : "text"
  }/${user.accountId}/${content.name}`;

export default Table;

const copyToClipboard = (str: string) => {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const DELETE_PHOTO = gql`
  mutation DeletePhoto($item: PhotoInput!) {
    photo(item: $item) {
      id
    }
  }
`;

const DELETE_TEXT = gql`
  mutation DeleteText($item: CellUpdateInput!) {
    cell(item: $item) {
      text
    }
  }
`;
