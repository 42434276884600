import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useQuery, gql } from "@apollo/client";
import Loading from "../../../components/loading";
import DisplayError from "../../../components/Error";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as PrevIcon } from "../../../static/back.svg";

const Container = styled.div`
margin-bottom: 10px;`;

const PhotosContainer = styled.div`
  overflow: scroll;
  scrollbar: visible;
  height: 75vh;
`;

const Photo = styled.img`
  width: 200px;
  height: 200px;
  cursor: pointer;
  object-fit: cover;
  margin: 10px;
`;

const NextIcon = styled(PrevIcon)`
  transform: rotate(180deg);
`;

const Pagination = styled.div`
  width: 100vw;
  justify-content: flex-end;
`;

const GoogleLibrary = () => {
  const history = useHistory();
  const { page }: any = useParams();
  const [pagination, setPagination] = useState({
    prev: "",
    next: "",
    curr: "",
  });
  const { data, loading, error } = useQuery(GET_GOOGLE_PHOTOS, {
    variables: {
      nextPage: page,
    },
  });
  useEffect(() => {
    if (data && data.googlePhotos.nextPage) {
      if (pagination.next) {
        setPagination({
          prev: JSON.parse(JSON.stringify(pagination.curr)),
          curr: page,
          next: data.googlePhotos.nextPage,
        });
      } else
        setPagination({
          prev: "",
          curr: "",
          next: data.googlePhotos.nextPage,
        });
    }
  }, [data]);

  return (
    <Container className="full flex vertical">
      <h1 className="title">Google Photo Library</h1>
      <h3 className="title">SELECT YOUR PHOTOS BELOW</h3>
      <PhotosContainer className="flex wrap center">
        {loading ? (
          <Loading />
        ) : error ? (
          <DisplayError error={error} />
        ) : (
          data.googlePhotos &&
          data.googlePhotos.items.map((photo: any) => (
            <Photo
              className="box round"
              onClick={() => history.push(`/content/${photo.id}`)}
              src={photo.url}
              key={photo.id}
            />
          ))
        )}
      </PhotosContainer>
      <Pagination className="flex">
        <PrevIcon
          className="svg-default image-button"
          onClick={() => history.goBack()}
        />
        <NextIcon
          className="svg-default image-button"
          onClick={() => history.push(`/google-library/${pagination.next}`)}
        />
      </Pagination>
    </Container>
  );
};

export default GoogleLibrary;

const GET_GOOGLE_PHOTOS = gql`
  query GooglePhotos($nextPage: String) {
    googlePhotos(nextPage: $nextPage) {
      nextPage
      items {
        url
        id
      }
    }
  }
`;
