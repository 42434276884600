import React, { FC } from "react";
import styled from "styled-components";

interface ISheetPreview {
  value: string;
}

const Wrapper = styled.div`
  margin: 10px;
  padding: 20px;
  width: 50%;
  max-width: 50%;
  border: 1px solid black;
`;
const SheetPrevew: FC<ISheetPreview> = ({ value }) => {
  return (
    <Wrapper>
      <h2>Preview</h2>
      <div>{value}</div>
    </Wrapper>
  );
};

export default SheetPrevew;
