import {
    SET_CURRENT_USER,
} from '../actions/session';
import { ReduxAction, User } from "../interfaces";

export interface SessionState {
    user?: User
}

const defaultState = {
    user: undefined
};

export default (state = defaultState, action: ReduxAction) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                user: action.value,
            };
        default:
            return state;
    }
};
