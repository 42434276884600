import React from "react";
import GoogleSignInButton, { oauthSignIn } from "./GoogleSignInButton";
import styled from "styled-components";
const Header = styled.h1`
  font-size: 6em;
  width: 100%;
  text-align: center;
  margin-block-end: 15px;
`;
const SubHeading = styled.h2`
  font-weight: 500;
  width: 100%;
  text-align: center;
`;
const LogInButton = styled.button`
background-color: #FBFF12;
border: none;
padding: 10px;
margin: 38px;
color: black;
`

const HowItWorksVideo = styled.div`
  width: 516px;
  height: 314px;
  // background: grey;
  border-radius: 25px;
`
const Login = () => (
  <div className="full flex center vertical">
    <div style={{ alignSelf: "flex-end" }}>
      <LogInButton onClick={ () => oauthSignIn() }>Existing User? Log In</LogInButton>
    </div>
    <div className="full flex center vertical">
      <Header>Google CMS</Header>
      <SubHeading>
        Access your content anywhere.
      </SubHeading>
      <HowItWorksVideo />
    </div>
    <div className="full">
      <GoogleSignInButton />
    </div>
  </div>
);

export default Login;
