import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Loading from "../../components/loading";
import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../redux/actions/session";
import DisplayError from "../../components/Error";

const Verify = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get("code");
  const history = useHistory();
  const dispatch = useDispatch();
  const [login, { data, called, error }] = useMutation(LOGIN);
  useEffect(() => {
    if (code && !called)
      login({ variables: { code } })
        .then((res) => {
          if (res.data && res.data.login)
            dispatch(setCurrentUser(res.data.login));
          history.push("/");
        })
        .catch(console.error);
  }, [code, login, called, history, dispatch]);

  if (error) return <DisplayError error={error} />;

  return (
    <>
      {data && data.login.email && <h1>Welcome, {data.login.name} </h1>}
      <Loading />
    </>
  );
};

export default Verify;

const LOGIN = gql`
  mutation login($code: String!) {
    login(code: $code) {
      email
      name
      accessToken
      profilePhoto
      accountId
    }
  }
`;
