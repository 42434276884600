import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  min-width: 100vw;
`;

const Option = styled.div`
  width: 250px;
  height: 250px;
  border-radius: 25px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  cursor: pointer;
`;
const Dashboard = () => {
  const router = useHistory();
  return (
    <Container className="full flex vertical">
      <h1 className="title bold">Welcome to your Google Media</h1>
      <h3 className="title">
        Add new content or review your existing content here.
      </h3>
      <div className="full flex wrap apart">
        <Option
          style={{ backgroundColor: "#FF206E" }}
          onClick={() => router.push("/cms")}
        >
          <h1>Review Your Library</h1>
        </Option>
        <Option
          style={{ backgroundColor: "#41EAD4" }}
          onClick={() => router.push("/add")}
        >
          <h1>Add New Items</h1>
        </Option>
      </div>
    </Container>
  );
};

export default Dashboard;
