import React from "react";
import styled from "styled-components";

const CLIENT_ID =
  "787243612215-62e4is3qjh5fthgrvihvomhuvtplnlaq.apps.googleusercontent.com";
const REDIRECT_URL =
  process?.env?.NODE_ENV === "development"
    ? "http://localhost:3000/verify"
    : "https://photos.ollieware.com/verify";

export function oauthSignIn() {
  const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

  const form = document.createElement("form");
  form.setAttribute("method", "GET"); // Send as a GET request.
  form.setAttribute("action", oauth2Endpoint);
  const params: any = {
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URL,
    response_type: "code",
    scope:
      "https://www.googleapis.com/auth/photoslibrary.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/spreadsheets.readonly",
    include_granted_scopes: "true",
    access_type: "offline",
    state: "pass-through value",
    // prompt: "consent",
  };

  // Add form parameters as hidden input values.
  for (let p in params) {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}

const Button = styled.button`
  background-color: #ff206e;
  border: none;
  color: white;
  padding: 10px;
  font-size: 22;
  font-weight: bold;
`;

const GoogleSignInButton = () => (
  <Button onClick={() => oauthSignIn()}>Try for Free</Button>
);

export default GoogleSignInButton;
