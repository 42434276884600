import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useQuery, gql } from "@apollo/client";
import Loading from "../../../components/loading";
import DisplayError from "../../../components/Error";
import Table from "./Table";

const Container = styled.div`
  width: 100%;
  min-width: 100vw;
`;

const CMS = () => {
  const { data, loading, error, refetch } = useQuery(GET_CMS_ITEMS, {
    fetchPolicy: "no-cache",
  });
  return (
    <Container className="full flex vertical">
      <h1 className="title bold">Here is your content library</h1>
      <h3 className="title" style={{ marginBottom: 50 }}>
        These are your media items that are publicly accessible.
      </h3>
      <div
        style={{ overflow: "scroll", justifyContent: "center" }}
        className="full flex wrap"
      >
        {loading ? (
          <Loading />
        ) : error ? (
          <DisplayError error={error} />
        ) : (
          <Table
            library={data.library}
            refetch={refetch}
            noContentMessage="No saved media items, please add them from your google library first"
          />
        )}
      </div>
    </Container>
  );
};

export default CMS;

const GET_CMS_ITEMS = gql`
  {
    library {
      url
      value
      id
      createdAt
      type
      name
    }
  }
`;
