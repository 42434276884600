import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./modules/Routes";
import styled from "styled-components";
import GraphQLProvider from "./modules/graphql/Provider";

const SiteWrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  // max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
`;

const Root = () => (
  <GraphQLProvider>
    <BrowserRouter>
      <SiteWrapper className="vertical flex">
        <Routes />
      </SiteWrapper>
    </BrowserRouter>
  </GraphQLProvider>
);

export default Root;
