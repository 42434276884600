import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../components/Button";
import SheetForm, { ISheet } from "./Form";
import SheetPrevew from "./Preview";
import gql from "graphql-tag";

const Container = styled.div`
  width: 100%;
  min-width: 100vw;
`;

const Wrapper = styled.div`
  display: flex;
  margin-top: 50px;
`;

const SheetWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 50px;
`;

const PreviewWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-left: 50px;
`;

const ButtonWrapper = styled.div`
  height: 20px;
  width: 90%;
  align-self: center;
  display: flex;
  justify-content: flex-end;
`;

const Sheet = () => {
  const [cellValue, setCellValue] = useState("");
  const [values, setValues] = useState<ISheet>();
  const [saveTextToLibrary] = useMutation(SAVE_TEXT);
  const router = useHistory();
  return (
    <Container className="full flex vertical">
      <h1 className="title bold">Add a Google Sheet Cell to your Library</h1>
      <h3 className="title">Fill out the required information below. </h3>
      <ButtonWrapper>
        <Button
          onClick={() => {
            saveTextToLibrary({
              variables: {
                item: {
                  ...values,
                  rowIndex: parseInt(values!.rowIndex),
                },
              },
            }).then(() => router.push("/cms"));
          }}
        >
          Add To Library
        </Button>
      </ButtonWrapper>
      <Wrapper>
        <SheetWrapper>
          <SheetForm
            onSearch={(value: string) => setCellValue(value)}
            onChange={(values: any) => setValues(values)}
          />
        </SheetWrapper>
        <PreviewWrapper>
          <SheetPrevew value={cellValue} />
        </PreviewWrapper>
      </Wrapper>
    </Container>
  );
};

export default Sheet;

const SAVE_TEXT = gql`
  mutation SaveText($item: CellInput!) {
    cell(item: $item) {
      text
    }
  }
`;
