import React from "react";
import styled from "styled-components";
import { ApplicationState } from "../../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearCurrentUser } from "../../../redux/actions/session";

const Settings = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((s: ApplicationState) => s.session.user!) || {};
    return (
        <div className="full flex column" style={{ width: "100vh" }}>
            <h1 className="title">SETTINGS</h1>
            <h3 className="title">CONFIGURE YOUR ACCOUNT BELOW</h3>
            <div className="flex" style={{ margin: "0px 50px" }}>
                <span className="full"><strong>NAME:</strong> {user.name}</span>
            </div>
            <div className="full" style={{ margin: "0px 50px" }}>
                <div><strong>ASSOCIATED GOOGLE ACCOUNT</strong>: {user.email} </div>
                <div><strong>YOUR ACCOUNT ID</strong>: {user.accountId}</div>
            </div>
            <div className="full center-self">
                <button onClick={() => {
                    dispatch(clearCurrentUser());
                    history.push("/login")
                }}>LOGOUT</button>
            </div>
        </div>
    )
}

export default Settings