import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client";
import styled from "styled-components";
import { useHistory } from "react-router-dom"

interface IContentForm {
    id: string
    isEdit: boolean
    content: any
}

const Container = styled.div`

`
const Input = styled.input`
width: 300px;
`
const AddLibrary = styled.button`
  margin-top: 25px;
  width: 300px;
`;

const Form: React.FC<IContentForm> = (props) => {
  const history = useHistory();
    const [savePhoto] = useMutation(SAVE_PHOTO);
    const defaultName = props.content ? props.content.name : "";
    const [name, setName] = useState(defaultName);
    return (
        <Container className="full flex center column">
            <Input placeholder="NAME" value={name} onChange={({ target: { value } }) => setName(value)} />
            <AddLibrary
                disabled={!name}
                onClick={() =>
                    savePhoto({
                        variables: {
                            photo: {
                                id: props.id,
                                name
                            },
                        },
                    }).then( () => history.push("/cms"))
                    .catch(console.error)
                }
            >
                { props.isEdit ? "SAVE" : "ADD TO LIBRARY" }
      </AddLibrary>
        </Container >
    )
}


const SAVE_PHOTO = gql`
  mutation SavePhoto($photo: PhotoInput!) {
    photo(item: $photo) {
      id
    }
  }
`;

export default Form