import React from "react";
import { ReactComponent as BackIcon } from "../static/camera-back.svg";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const StyledButton = styled(BackIcon)``;

const BackButton = () => {
  const history = useHistory();

  return (
    <StyledButton
      className="svg-default image-button"
      onClick={() => history.goBack()}
    />
  );
};

export default BackButton;
