import React, { useEffect } from "react";
import Header from "./Header";
import GoogleLibrary from "./googleLibrary";
import CMS from "./cms";
import Add from "./add";
import styled from "styled-components";
import { Switch, Route, Redirect } from "react-router-dom";
import Content from "./content";
import { useQuery, gql } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { setCurrentUser } from "../../redux/actions/session";
import Settings from "./settings";
import Dashboard from "./dashboard";
import Sheet from "./add/sheet/Sheet";

const Container = styled.div``;
const Wrapper = styled.div`
  // height: 90vh;
`;
const Home = () => {
  const user = useSelector((s: ApplicationState) => s.session.user);
  const accessToken = user ? user.accessToken : "";
  const dispatch = useDispatch();
  console.log("User: ", user);
  const { data, error, stopPolling, startPolling } = useQuery(
    REFRESH_TOKEN_QUERY,
    {
      skip: true,
    }
  );
  useEffect(() => {
    startPolling(60000 * 1);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  useEffect(() => {
    if (
      data &&
      data.refreshToken.accessToken &&
      data.refreshToken.accessToken !== accessToken &&
      user
    )
      dispatch(
        setCurrentUser({
          ...user,
          accessToken: data.refreshToken.accessToken,
        })
      );
  }, [data, accessToken, user, dispatch]);
  return (
    <Container className="flex full vertical">
      <Header />
      <Wrapper className="flex">
        <Switch>
          <Redirect exact from="/" to="/dashboard" />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/google-library/:page" component={GoogleLibrary} />
          <Route path="/google-library" component={GoogleLibrary} />
          <Route path="/cms" component={CMS} />
          <Route path="/add" component={Add} exact />
          <Route path="/add/photo" component={GoogleLibrary} exact />
          <Route path="/add/sheet" component={Sheet} exact />
          <Route path="/settings" component={Settings} />
          <Route path="/content/:id" component={Content} />
          <Route path="/edit/:id" component={Content} />
        </Switch>
      </Wrapper>
    </Container>
  );
};

export default Home;

const REFRESH_TOKEN_QUERY = gql`
  {
    refreshToken {
      accessToken
    }
  }
`;
