import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import "./sideBar.css";
import { ReactComponent as GoogleLibraryIcon } from "../../static/art.svg";
import { ReactComponent as ContentIcon } from "../../static/content.svg";
import { ReactComponent as SettingIcon } from "../../static/interface.svg";
import { useHistory } from "react-router-dom";

const SideBarContainer = styled.div`
  position: absolute;
  left: -310px;
  top: 100px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  width: 300px;
  height: 100vh;
  background-color: black;
  // background-color: #e5e5e5;
`;

const Wrapper = styled.div`
  // position: absolute;
  // background: rgba(255, 255, 255, 0.5);
  // top: 50%;
  // left: 50%;
  // min-width: 100%;
  // min-height: 100%;
  // max-width: 100vw;
  // width: auto;
  // height: auto;
  // transform: translateX(-50%) translateY(-50%);
`;

const Link = styled.div`
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid white;
  cursor: pointer;
  color: white;
  :hover {
    color: grey;
    border-bottom: 1px solid grey;
    .svg-default {
      fill: grey;
    }
  }
`;

const Text = styled.span`
  margin-left: 10px;
`;

const SettingsWrapper = styled.div`
align-contnet: flex-end;
`

interface ISideBar {
  open: boolean;
  onClose: Function;
}

const SideBar = (props: ISideBar) => {
  const history = useHistory();
  const goTo = (path: string) => {
    history.push(path);
    props.onClose();
  };
  return (
    <Wrapper
      // className={`${!props.open ? "hidden" : ""}`}
      id="sidebar-background"
      onClick={(e) => {
        console.log("e", e.target);
        if ((e.target as any).getAttribute("id") === "sidebar-background")
          props.onClose();
      }}
    >
      <SideBarContainer
        id="navigation-sidebar"
        className={`flex vertical slide ${props.open ? "in" : "out"}`}
      >
        <Link className="flex" onClick={() => goTo("/google-library")}>
          <GoogleLibraryIcon className="svg-default" style={{ fill: "white" }} />
          <Text> All Google Photos</Text>
        </Link>
        <Link className="flex" onClick={() => goTo("/cms")}>
          <ContentIcon className="svg-default" style={{ fill: "white" }} />
          <Text>Your Published Content</Text>
        </Link>
        <SettingsWrapper className="full flex column" style={{ justifyContent: "flex-end"}}>
          <Link style={{ borderTop: "1px solid white"}} className="flex" onClick={() => goTo("/settings")}>
            <SettingIcon className="svg-default" style={{ fill: "white" }} />
            <Text>SETTINGS</Text>
          </Link>
        </SettingsWrapper>
      </SideBarContainer>
    </Wrapper>
  );
};

export default SideBar;
