import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import session, { SessionState } from './reducers/session';
export interface ApplicationState {
    session: SessionState;
}
const reducers = combineReducers({
    session,
});
const persistConfig = {
    key: 'root',
    storage,
    blacklist: [],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(persistedReducer, {}, applyMiddleware(thunk));
export const persistor = persistStore(store as any);
export default store;
