import React from "react";
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";

const GraphQLProvider: React.FC = (props) => {
  const user = useSelector((s: ApplicationState) => s.session.user);
  // const accessToken =
  // "ya29.a0AfH6SMAvZwoItlp5c0hb9CIcbmQaRth3ZoMHnhnkMTPa-7KRFlnrA4o4Vz9sq7H3qaQ4tsqHTto0tc7zYpxUHNCXKlXEBzTZA41S2nYHVyXYovHIX05ZcTUyEmGia0jbtVs0p2TcsBZwioK-bj8Fi1wOSiNtqKkRgl--Rbj1P84ya29.a0AfH6SMC_t4wBOLN5Bb14pQ6eUE1pX3BIHrbAdTPKX2XRLxKlNfxs1bsqCH4TfWVuA4eYdrrWlhMJcjTZ3ickTzZTSMzO7iHMIJfXotraX7tAxf21E3Ktkoa8m93Ktyc4ofsAXMP--zydymrfGeuN-FbyhYXYBvpsNgRNVzi4Ir8";
  const accessToken = user ? user.accessToken : "";

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
      uri: process.env.REACT_APP_BASE_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export default GraphQLProvider;
