import React from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { gql, useQuery, useMutation } from "@apollo/client";
import Loading from "../../../components/loading";
import DisplayError from "../../../components/Error";
import BackButton from "../../../components/BackButton";
import Form from "./Form";
import Jodhpur from "../../../static/Jodhpur.png"

const Wrapper = styled.div`
`

const Image = styled.img`
  width: 80%;
  object-fit: cover;
`;

const Video = styled.video`
  width: 80%;
  object-fit: cover;
`;

const BackWrapper = styled.div`
  height: 60px;
  width: 100vw;
  align-items: center;
`;

const Content = () => {
  console.log(Jodhpur)
  const { id } = useParams();
  const isEdit = !!useRouteMatch("/edit")
  const QUERY = isEdit ? GET_CONTENT : GET_GOOGLE_CONTENT
  const { data, error, loading } = useQuery(QUERY, {
    variables: { id },
  });
  const PATH = isEdit ? "content" : "googlePhoto"
  return (
    <div className="full flex vertical">
      <BackWrapper className="flex">
        <BackButton />
      </BackWrapper>

      {loading ? (
        <Loading />
      ) : error ? (
        <DisplayError error={error} />
      ) : (
            <Wrapper className="full flex center wrap">
              <div className="full flex center" style={{ minWidth: "300px"}}>
                {data[PATH].mimeType === "video/mp4" ? (
                  <Video controls>
                    <source src={`${data[PATH].url}=dv`} type="video/mp4" />
                  </Video>
                ) : (
                    <Image src={data[PATH].url} alt="selected CMS" />
                  )}
              </div>
              <Form id={id!} isEdit={isEdit} content={data[PATH]} />
            </Wrapper>
          )}
    </div>
  );
};

export default Content;

const GET_GOOGLE_CONTENT = gql`
  query GooglePhoto($id: String!) {
    googlePhoto(id: $id) {
      url
      mimeType
    }
  }
`;


const GET_CONTENT = gql`
  query Content($id: String!) {
    content(id: $id) {
      url
      mimeType
      name
    }
  }
`;

