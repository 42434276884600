import React, { useState } from "react";
import styled from "styled-components";
// import { ReactComponent as SearchIcon } from "../../static/search.svg";
import { ReactComponent as MenuIcon } from "../../static/menu.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import SideBar from "./SideBar";

const Wrapper = styled.div`
  height: 10vh;
  width: 100%;
  padding: 10px;
`;

const ImageIcon = styled(MenuIcon)`
  height: 20px;
  margin: 20px;
`;

const Name = styled.span`
  font-weight: 400;
  font-size: 1em;
  margin-right: 10px;
  cursor: pointer;
`;
const ProfilePhoto = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  object-fit: contain;
  cursor: pointer;
`;

const SL = styled.div`
  border-radius: 100%;
  background: yellow;
  width: 48px;
  height: 48px;
  font-size: 32px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SmallLogo = ({ className, onClick }: any) => (
  <SL {...{ className, onClick }} style={{}}>
    G
  </SL>
);

const Header = () => {
  const history = useHistory();
  const [isSideBarOpen, setSideBarOpen] = useState(false);
  const user = useSelector((s: ApplicationState) => s.session.user);
  const router = useHistory();
  const name = user && user.name;
  const profilePhoto = user && user.profilePhoto;
  return (
    <Wrapper className="flex center">
      <div className="full">
        <SmallLogo
          className="image-button center"
          onClick={() => router.push("/")}
          // onClick={() => setSideBarOpen(!isSideBarOpen)}
        />
      </div>
      <div style={{ margin: "0px 20px" }} className="flex center">
        <Name onClick={() => history.push("/settings")}>{name}</Name>
        <ProfilePhoto
          onClick={() => history.push("/settings")}
          src={profilePhoto}
        />
      </div>
      <SideBar open={isSideBarOpen} onClose={() => setSideBarOpen(false)} />
    </Wrapper>
  );
};
export default Header;
