import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearCurrentUser } from "../redux/actions/session";

const ErrorText = styled.span`
  font-size: 1.5em;
  color: red;
  width: 100%;
  height: 100%;
  margin: 25px;
`;

const DisplayError: React.FC<{ error: any }> = ({ error }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  console.log("ERROR: ", JSON.stringify(error));
  const errorMessage: string = error.networkError
    ? error.networkError.result
      ? error.networkError.result.errors.map((e: any) => e.message).join(", ")
      : error.networkError.message
    : error.graphQLErrors
    ? error.graphQLErrors.map((e: any) => e.message).join(", ")
    : error.toString();
  if (errorMessage === "Not Authorized") {
    dispatch(clearCurrentUser());
    history.push("/login");
  }
  return (
    <ErrorText>
      Sorry, we ran into an issue. If this persists, please contact us.
      <br />
      <br />
      Exception: {errorMessage}
    </ErrorText>
  );
};

export default DisplayError;
